@charset "utf-8";

//Variables
$accent: null;
$anchor_color: null;
$anchor_color_light: null;
$az_color: null;
$az_color_mobile: null;
$backdrop_background: null;
$bg_body_line: null;
$bg_button_line: null;
$bgColor: null;
$bg_main: null;
$bg_title_lines: null;
$borderColor: null;
$bet-btn-active: null;
$breadcrumb-background: null;
$breadcrumb-border: null;
$breadcrumb-color: null;
$breadcrumb-color-active: null;
$breadcrumb-dark-back: null;
$breadcrumb-divider: quote("\43");
$breadcrumb-fav: null;
$breadcrumb-open-bg: null;
$breadcrumb-open-items: null;
$breadcrumb-open-items-color: null;
$breadcrumbBg: null;
$bread_active_color: null;
$accent: null;
$ctaColor: null;
$color: null;
$dropdown_anchor: null;
$dropdown_header: null;
$dropdown_header_color: null;
$dropdown_item: null;
$dropdown_right_anchor: null;
$dropdown_title: null;
$dropdown_title_color: null;
$favorite_btn: null;
$fontColor: null;
$headerBg: null;
$hyperlink-accent: null;
$left_menu_link_color: null;
$left_menu_link_color_mobile: null;
$light_theme_color: null;
$line-active: null;
$bg_body_line: null;
$line-black-color: null;
$line-button-active-bg: null;
$line-button-bg: null;
$line-button-color: null;
$line-type-color: null;
$lines-box-border: null;
$lines-inactive-prop: null;
$lines-inactive-stats: null;
$lines-live: null;
$lines-top-bet: null;
$live-color: null;
$main_font: 'Montserrat', sans-serif;
$menuInnerBg: null;
$menuTitleBg: null;
$nav-anchor: null;
$nav-bg: null;
$nav_border: null;
$nav-border: null;
$nav-color: null;
$nav-color-bold: null;
$odds_down_color: #EA0070;
$odds_up_color: #70A800;
$promo_body_background: null;
$promo_body_color_disabled: null;
$promo_btn_active: null;
$promo_btn_disabled: null;
$promo_font_fam: null;
$promo_header_background: null;
$promo_header_color: null;
$promo_link_color: null;
$sportsbook-lines-font: null;
$sportsbook-news-bg: #ebeaeb;
$sportsbook-news-btns: #3b6baa;
$sportsbook-news-link: #67a037;
$sportsbook-news-link-hover: #fff;
$sportsbook-promo-code-bg: #ebeaeb;
$sportsbook-promo-code-btns: #93A3B2;
$sportsbook-promo-code-link: #231F20;
$sportsbook-promo-code-link-hover: #231F20;
$sports_lines_button_color: null;
$sports_lines_button_span_color: null;
$spread_money_total_lines: null;
$color_button_line_second: null;
$team-lines-background: null;
$title-color: null;
$titleColor: null;
$top-body-bg: null;
$top-color: null;
$top-header-bg: null;
$top-header-color: null;
$upcoming-color: null;
$white-color: #fff;
$xb_buttons_background: null;
$xb_buttons_border: null;
$lines_button_active_color: null;
$lines_button_active_background: null;
$lines_button_active_span_color: null;
$lines_buttton_change_background: null;
$lines-sports-button-text: null;
$match_prop_lines_anchor_color: null;
$match_prop_lines_background: null;
$match_prop_lines_inactive_matchup: null;
$match_prop_lines_inactive_prop: null;
$mach-prop-lines-color: null;

@if $brand == 'betusracing' {
  $anchor_color: white;
  $anchor_color_light: #000;
  $backdrop_background: #cccccc54;
  $bg_light: white;
  $breadcrumb_background: #1360a0;
  $breadcrumb_dark_back: #1360a0;
  $bread_active_color: #fff;
  $color_button_line_second: $accent;
  $dropdown_anchor: white;
  $dropdown_header: #93a3b2;
  $dropdown_header_color: #000;
  $dropdown_item: #93a3b2;
  $dropdown_right_anchor: #93a3b2;
  $dropdown_title: #93a3b2;
  $dropdown_title_color: #171d1c;
  $light_theme_color: black;
  $mach-prop-lines-background: white;
  $mach-prop-lines-color: #1672bd;
  $match_prop_lines_anchor_color: #1672bd;
  $match_prop_lines_inactive_matchup: #CFD6DD;
  $match_prop_lines_inactive_prop: #CFD6DD;
  $nav-anchor: #000000;
  $nav_border: white;
  $nav_border: white; // Repeated variable
  $odds_down_color: #EA0070;
  $odds_up_color: #70A800;
  $promo_body_background: #ebeaeb;
  $promo_body_color_disabled: #9A9B9F;
  $promo_btn_active: #1360A0;
  $promo_btn_disabled: #93A3B2;
  $promo_font_fam: 'Lato', sans-serif;
  $promo_header_background: #1360A0;
  $promo_header_color: white;
  $promo_link_color: #231F20;
  $sports_lines_button_color: #6C7070;
  $sports_lines_button_span_color: #171D1C;
  $sportsbook-lines-font: 'Lato', sans-serif;
  $spread_money_total_lines: #F0F1F3;
  $team-lines-background: white;
  $lines_button_active_background: #1C67BB;
  $lines_button_active_color: white;
  $lines_button_active_span_color: white;
  $lines_buttton_change_background: #F48220;
  $lines-sports-button-text: #fff;
}

@if $brand == 'mybookie' {
  $accent: #F48220;
  $anchor_color: white;
  $anchor_color_light: #000;
  $az_color: #fff;
  $az_color_mobile: #FFFFFF;
  $backdrop_background: #cccccc54;
  $bet-btn-active: $accent;
  $bg_body_line: #242526;
  $bg_button_line: hsla(0,0%,93%,.15);
  $bgColor: #242526;
  $bg_main: #333435;
  $bg_title_lines: $bg_main;
  $breadcrumb_background: #485468;
  $breadcrumb_dark_back: #485468;
  $breadcrumbBg: $bg_main;
  $breadcrumb-color: $az_color;
  $breadcrumb-color-active: $accent;
  $bread_active_color: $accent;
  $breadcrumb_background: #231F20;
  $breadcrumb_dark_back: #231F20;
  $ctaColor: null;
  $color: $accent;
  $color_button_line_second: $accent;
  $dropdown_anchor: white;
  $dropdown_header: #93a3b2;
  $dropdown_header_color: #000;
  $dropdown_item: #93a3b2;
  $dropdown_right_anchor: white;
  $dropdown_title: #93a3b2;
  $dropdown_title_color: #171d1c;
  $favorite_btn: white;
  $fontColor: #fff;
  $headerBg: #272829;
  $hyperlink-accent: $accent;
  $left_menu_link_color: #fff;
  $left_menu_link_color_mobile: #FFFFFF;
  $light_theme_color: black;
  $line-black-color: $anchor_color;
  $live-color: #91D146;
  $mach-prop-lines-background: white;
  $mach-prop-lines-color: #F48220;
  $match_prop_lines_anchor_color: #F48220;
  $match_prop_lines_inactive_matchup: #CFD6DD;
  $match_prop_lines_inactive_prop: #CFD6DD;
  $menuInnerBg: #242526;
  $menuTitleBg: #272829;
  $nav-anchor: #000000;
  $nav-bg: #242526;
  $nav_border: #58595A;
  $nav-border: #58595a;
  $nav-color: #fff;
  $nav-color-bold: #fff;
  $odds_down_color: #EA0070;
  $odds_up_color: #70A800;
  $promo_body_background: #ebeaeb;
  $promo_body_color_disabled: #9A9B9F;
  $promo_btn_active: $accent;
  $promo_btn_disabled: #93A3B2;
  $promo_font_fam: 'Oswald', sans-serif;
  $promo_header_background: #231F20;
  $promo_header_color: white;
  $promo_link_color: #231F20;
  $sports_lines_button_color: #6C7070;
  $sports_lines_button_span_color: #171D1C;
  $sportsbook-lines-font: 'Oswald', sans-serif;
  $spread_money_total_lines: #F0F1F3;
  $sub_menu_items_colors: #fff;
  $submenu_items_colors: #fff;
  $submenu_items_colors_mobile: #FFFFFF;
  $team-lines-background: white;
  $titleColor: $accent;
  $title_color: $accent;
  $top-color: $accent;
  $upcoming-color: #1976D2;
  $lines-top-bet: $accent;
  $lines_button_active_background: #1C67BB;
  $lines_button_active_color: white;
  $lines_button_active_span_color: white;
  $lines_buttton_change_background: #F48220;
  $lines-sports-button-text: #fff;
  $line-type-color: hsla(0,0%,100%,.75);
}

@if $brand == 'xbet' {
  $accent: #07DD07;
  $anchor_color: white;
  $anchor_color_light: #000;
  $az_color: #fff;
  $az_color_mobile: #FFFFFF;
  $backdrop_background: #cccccc54;
  $bgColor: #231F20;
  $bg_button_line: #324545;
  $bg_main: #283C39;
  $bg_title_lines: $bg_main;
  $bread_active_color: $accent;
  $bg_body_line: #231F20;
  $breadcrumb_background: #485468;
  $breadcrumb_dark_back: #485468;
  $bet-btn-active: #383fbe;
  $ctaColor: null;
  $color: $accent;
  $color_button_line_second: #00FFCE;
  $dropdown_anchor: white;
  $dropdown_header: #93a3b2;
  $dropdown_header_color: #000;
  $dropdown_item: #93a3b2;
  $dropdown_right_anchor: white;
  $dropdown_title: #93a3b2;
  $dropdown_title_color: #171d1c;
  $favorite_btn: white;
  $fontColor: #fff;
  $headerBg: $bg_main;
  $hyperlink-accent: #00FFCE;
  $left_menu_link_color: #fff;
  $left_menu_link_color_mobile: #FFFFFF;
  $light_theme_color: black;
  $line-black-color: $anchor_color;
  $line-active: $accent;
  $live-color: $accent;
  $main_font: 'Titillium Web', sans-serif;
  $mach-prop-lines-background: white;
  $mach-prop-lines-color: #71a400;
  $match_prop_lines_anchor_color: #71a400;
  $match_prop_lines_inactive_matchup: #CFD6DD;
  $match_prop_lines_inactive_prop: #CFD6DD;
  $menuInnerBg: #231F20;
  $menuTitleBg: $bg_main;
  $nav-anchor: #000000;
  $nav-bg: $bg_main;
  $nav_border: white;
  $nav-border: #58595A;
  $nav-color: #fff;
  $nav-color-bold: #fff;
  $odds_down_color: #EA0070;
  $odds_up_color: #70A800;
  $promo_body_background: #ebeaeb;
  $promo_body_color_disabled: #9A9B9F;
  $promo_btn_active: $accent;
  $promo_btn_disabled: #93A3B2;
  $promo_font_fam: $main_font;
  $promo_header_background: #485468;
  $promo_header_color: white;
  $promo_link_color: #231F20;
  $sports_lines_button_color: #6C7070;
  $sports_lines_button_span_color: #171D1C;
  $sportsbook-lines-font: $main_font;
  $spread_money_total_lines: #F0F1F3;
  $sub_menu_items_colors: #fff;
  $submenu_items_colors: #fff;
  $submenu_items_colors_mobile: #FFFFFF;
  $team-lines-background: white;
  $titleColor: $accent;
  $title_color: $accent;
  $top-color: $accent;
  $upcoming-color: #1976D2;
  $xb_buttons_background: #F0F1F3;
  $xb_buttons_border: #485468;
  $lines_button_active_background: #485468;
  $lines_button_active_color: white;
  $lines_button_active_span_color: white;
  $lines_buttton_change_background: #F48220;
  $lines-sports-button-text: #fff;
  $lines-top-bet: $accent;
  $breadcrumbBg: $bg_main;
  $breadcrumb-color: $az_color;
  $breadcrumb-color-active: $accent;
}
