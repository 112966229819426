.sports-menu-title {
  font: normal 600 normal 16px/18px $main_font;
  text-transform: uppercase;
  margin: 0 0 10px 0;
  border-bottom: 1px solid #FCFCF9;
  padding: 0.5rem 0;
  color: $title_color;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  a {
    color: $title_color;

    &:hover, &:focus {
      color: $title_color;
    }
  }

  i {
    transform: rotate(180deg);
    color: $left_menu_link_color_mobile;
    @include mq-min(768px) {
      color: $left_menu_link_color;
    }
  }

  &.collapsed {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    i {
      transform: rotate(0deg);
    }
  }

  @include mq-min(768px) {
    background: $menuTitleBg;
    padding: 13px 8px;
    border-bottom: none;
    margin-bottom: 0;
    @if $brand == 'mybookie' {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      &.collapsed {
        border-radius: 8px;
      }
    }
  }
  @include mq-min(1024px) {
    font: normal 600 normal calc(0.1em + 0.8vw)/22px $main_font;
  }

  &.feature {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.az-sports {
    color: $az_color_mobile;
    font: normal 600 normal 16px/18px $main_font;
    margin: 1rem 0;
    background: none;
    border-bottom: 1px solid #FCFCF9;
    @include mq-min(768px) {
      color: $az_color;
      border-bottom: none;
      padding: 0;
    }
    @include mq-min(1024px) {
      font: normal 600 normal calc(0.1em + 1vw)/22px $main_font;
      margin: 1.1rem 0;
    }
  }

  &.sub-featured {
    a {
      color: $left_menu_link_color_mobile;

      &:hover, &:focus {
        color: $left_menu_link_color_mobile;
      }
    }

    @include mq-min(768px) {
      background: $menuInnerBg;
      a {
        color: $left_menu_link_color;

        &:hover, &:focus {
          color: $left_menu_link_color;
        }
      }
    }
    @include mq-min(1280px) {
      font: normal 600 normal calc(0.1em + 0.7vw)/16px $main_font;
    }
  }
}

//.featured-lines-menu{
//  h3{
//    display: none;
//  }
//}
.menu-body {
  @include mq-min(768px) {
    background: $menuInnerBg;
    @if $brand == 'mybookie' {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.left-menu-group {
  //padding: .5rem;
  &__header {
    &__title {
      font: normal 600 normal 14px/18px $main_font;
      text-transform: uppercase;
      color: $title_color;
      margin: 10px 0 0 0;
      @include mq-min(1024px) {
        font: normal 600 normal calc(0.1em + 0.8vw)/22px $main_font;
      }

      &.sub-menu-parent {
        font: normal 600 normal 12px/15px $main_font;
        padding: 0.5rem 0.5rem;
        margin: 0;
        color: $left_menu_link_color_mobile;
        @include mq-min(768px) {
          color: $left_menu_link_color;
        }

        i {
          transform: rotate(180deg);
        }

        &.collapsed {
          @if $brand == 'mybookie' {
            border-radius: 8px;
          }

          i {
            transform: rotate(0deg);
          }
        }

        @include mq-min(1280px) {
          font: normal 600 normal calc(0.1em + 0.8vw)/16px $main_font;
        }
      }
    }
  }

  .sub-items-menu {
    &__body {
      &__item {
        &__link {
          font: normal 600 normal 12px/15px $main_font;
          color: $left_menu_link_color_mobile;
          padding: 0.5rem 0;
          @include mq-min(768px) {
            color: $left_menu_link_color;
            padding: 0.5rem;
          }
          @include mq-min(1024px) {
            font: normal 600 normal calc(0.1em + 0.6vw)/16px $main_font;
          }

          &.sub-menu-link {
            padding: 0.5rem 0 0.5rem 1rem;
          }

          &.menu_active {
            color: $title_color;
          }
        }
      }
    }
  }
}

.latest-news__read-more-btn:hover {
  color: $title_color;
}

.parent-league-menu.collapsed {
  margin-bottom: 10px;
}

.logo_img {
  @media only screen and (max-width: 800px) {
    margin-left: 60% !important;
  }
}