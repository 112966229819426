.text-custom-primary {
  color: $color !important;
}

.carousel-container {
  padding: 15px 8px;
  background: $bg_main;
  @if $brand == 'mybookie' {
    background: #242526;
  }
  @include mq-min(768px) {
    @if $brand == 'mybookie' {
      padding: 32px 8px;
    }
    @if $brand == 'xbet' {
      padding: 0 0 0.8rem 0;
    }
  }

  @if $brand == 'mybookie' {
    .carousel-item div {
      padding: 0 8px;
    }

    .carousel-item div img {
      border-radius: 8px;
    }

    @media (max-width: 760px) {
      .carousel-inner .carousel-item > div {
        display: none;
      }

      .carousel-inner .carousel-item > div:first-child {
        display: block;
      }
    }
    .carousel-inner .carousel-item.active,
    .carousel-inner .carousel-item-next,
    .carousel-inner .carousel-item-prev {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    /* display 3 */
    @media (min-width: 768px) {

      .carousel-inner .carousel-item-right.active,
      .carousel-inner .carousel-item-next {
        transform: translateX(41.666667%);
      }

      .carousel-inner .carousel-item-left.active,
      .carousel-inner .carousel-item-prev {
        transform: translateX(-41.666667%);
      }

      .carousel-inner .carousel-item-right {
        transform: translateX(0);
      }
      .carousel-inner .carousel-item-left {
        transform: translateX(0);
      }
    }
  }

  .arrow {
    display: none;
  }

  &:hover {
    .arrow {
      display: flex;
    }
  }

  .carousel-control-next, .carousel-control-prev {
    width: 5%;
  }

  .swiper-button-prev {
    width: 40px;
    height: 40px;
  }

  .swiper-button-next {
    width: 40px;
    height: 40px;
  }

}

.seg-popup {
  display: none
}

@keyframes loadingC {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.line {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: $color;
}

.load-3 .line:nth-last-child(1) {
  animation: loadingC 0.6s 0.1s linear infinite;
}

.load-3 .line:nth-last-child(2) {
  animation: loadingC 0.6s 0.2s linear infinite;
}

.load-3 .line:nth-last-child(3) {
  animation: loadingC 0.6s 0.3s linear infinite;
}

.load-wrapp {
  width: 100%;
  height: auto;
  padding: 33px 42px 48px !important;
  @if $brand == 'mybookie' {
    border-radius: 5px;
    margin: 0 10px 10px 0;
  }
  text-align: center;
  background-color: #d8d8d8;

  p {
    padding: 0 0 5px;
  }

  &:last-child {
    margin-right: 0;
  }
}
