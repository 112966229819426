/**
 * MIXIN: Responsive Media Queries.
 *
 * Creates responsive media queries for eight different screen sizes.
 * These are based on min-width which means if x is the size then your
 * CSS will affect any device with screen width x and above.
 *
 * USAGE: 
 *	@include r(240)  { }
 *	@include r(320)  { }
 *	@include r(480)  { }
 *	@include r(768)  { }
 *	@include r(992)  { }
 *	@include r(1024) { }
 *	@include r(1140) { }
 *	@include r(1280) { }
 *	@include r(1500) { }
 *
 */
@mixin r( $point ) {
	@if $point==240 {
		@media ( min-width: 240px ) {
			@content;
		}
	}
	@if $point==320 {
		@media ( min-width: 320px ) {
			@content;
		}
	}
	@if $point==480 {
		@media ( min-width: 480px ) {
			@content;
		}
	}
	@if $point==600 {
		@media ( min-width: 600px ) {
			@content;
		}
	}
	@if $point==768 {
		@media ( min-width: 768px ) {
			@content;
		}
	}
	@if $point==992 {
		@media ( min-width: 992px ) {
			@content;
		}
	}
	@if $point==1024 {
		@media ( min-width: 1024px ) {
			@content;
		}
	}
	@if $point==1140 {
		@media ( min-width: 1140px ) {
			@content;
		}
	}
	@if $point==1280 {
		@media ( min-width: 1280px ) {
			@content;
		}
	}
	@if $point==1500 {
		@media ( min-width: 1500px ) {
			@content;
		}
	}
}

/**
 * MIXIN: Responsive Media Queries.
 *
 * Creates responsive media queries for eight different screen sizes.
 * These are based on min-width which means if x is the size then your
 * CSS will affect any device with screen width x and above.
 *
 * USAGE:
 *	@include resolution(240)  { }
 *	@include resolution(320)  { }
 *	@include resolution(480)  { }
 *	@include resolution(768)  { }
 *	@include resolution(992)  { }
 *	@include resolution(1024) { }
 *	@include resolution(1140) { }
 *	@include resolution(1280) { }
 *	@include resolution(1500) { }
 *
 */
@mixin resolution( $point ) {
	@if $point==240 {
		@media ( min-width: 240px ) {
			@content;
		}
	}
	@if $point==320 {
		@media ( min-width: 320px ) {
			@content;
		}
	}
	@if $point==480 {
		@media ( min-width: 480px ) {
			@content;
		}
	}
	@if $point==600 {
		@media ( min-width: 600px ) {
			@content;
		}
	}
	@if $point==768 {
		@media ( min-width: 768px ) {
			@content;
		}
	}
	@if $point==992 {
		@media ( min-width: 992px ) {
			@content;
		}
	}
	@if $point==1024 {
		@media ( min-width: 1024px ) {
			@content;
		}
	}
	@if $point==1140 {
		@media ( min-width: 1140px ) {
			@content;
		}
	}
	@if $point==1280 {
		@media ( min-width: 1280px ) {
			@content;
		}
	}
	@if $point==1500 {
		@media ( min-width: 1500px ) {
			@content;
		}
	}
}
/****************Orientation***********************/

@mixin center($horizontal: true, $vertical: true) {
	position: absolute;
	@if ($horizontal and $vertical) {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if ($horizontal) {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if ($vertical) {
		top: 50%;
		transform: translate(0, -50%);
	}
}

@mixin mq-min($min-width) {
	@media screen and (min-width: $min-width) {
		@content;
	}
}

@mixin mq-max($max-width) {
	@media screen and (max-width: $max-width) {
		@content;
	}
}

@mixin scroll-list {
	cursor: pointer;
	display: flex;
	list-style: none;
	margin: 0;
	overflow: auto;
	white-space: nowrap;
	webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-overflow-style: none;
}

$webkit_thumb_background_1: #F5F5F5;
$webkit_thumb_background: #dbdfe4;
@mixin overflow-scroll{
	&::-webkit-scrollbar-track
	{
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		border-radius: 8px;
		background-color: $webkit_thumb_background_1;
	}

	&::-webkit-scrollbar
	{
		width: 5px;
		height: 5px;
		background-color: $webkit_thumb_background_1;
	}

	&::-webkit-scrollbar-thumb
	{
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
		background-color: $webkit_thumb_background;
	}
}

@mixin bet-slip-input {
	font: normal 600 normal 12px/12px $main_font;
	color: $bet-slip-input-color;
	text-align: right;
	border: 1px solid $bet-slip-input-border-color;
	max-width: 80px;
	height: 24px;
	border-radius: 4px;
	padding: 0 8px;
	&:focus{
		outline: none;
		box-shadow: none;
	}
}
