@import 'helpers/variables.scss';
@import 'helpers/mixins.scss';

@import "components/sportsbook-carousel";
@import "components/sports-left-menu-v2";
.playerStats {
  background-color: $bg_body_line;

  &__name {
    color: $accent;
  }
}

.teamStats {
  background-color: $bg_body_line;
  border: 1px solid $bg_body_line;
  color: #FFFFFF;
}
